export const enum TerminalTextStyle {
  Green,
  Sub,
  Subber,
  Text,
  White,
  Red,
  Blue,
  Pink,
  Invisible,
  Underline,
  Mythic,
}
