/* eslint-disable */
const genericVerb = [
  "ruin",
  "snow",
  "ban",
  "blot",
  "disappear",
  "scare",
  "relax",
  "tug",
  "sigh",
  "rub",
  "float",
  "entertain",
  "tap",
  "taste",
  "gather",
  "tour",
  "place",
  "treat",
  "program",
  "try",
  "depend",
  "puncture",
  "cheat",
  "whine",
  "tame",
  "check",
  "employ",
  "heap",
  "flower",
  "perform",
  "unite",
  "guarantee",
  "delight",
  "expand",
  "double",
  "fit",
  "ignore",
  "phone",
  "regret",
  "question",
  "jog",
  "attract",
  "learn",
  "twist",
  "drip",
  "remind",
  "slap",
  "knock",
  "train",
  "part",
  "groan",
  "compete",
  "excite",
  "calculate",
  "trouble",
  "shrug",
  "whip",
  "prevent",
  "object",
  "return",
  "scribble",
  "practise",
  "carve",
  "possess",
  "irritate",
  "park",
  "stamp",
  "warm",
  "imagine",
  "fade",
  "admire",
  "hang",
  "unlock",
  "obtain",
  "smash",
  "queue",
  "develop",
  "end",
  "pine",
  "balance",
  "fold",
  "bat",
  "crawl",
  "frighten",
  "dry",
  "approve",
  "rhyme",
  "contain",
  "sip",
  "battle",
  "peel",
  "trick",
  "chop",
  "heat",
  "wreck",
  "please",
  "preach",
  "risk",
  "kiss",
  "test",
  "thaw",
  "wander",
  "bless",
  "walk",
  "cover",
  "form",
  "seal",
  "reduce",
  "wave",
  "support",
  "coach",
  "spoil",
  "sniff",
  "borrow",
  "zoom",
  "obey",
  "worry",
  "shock",
  "suffer",
  "cry",
  "heal",
  "harm",
  "suspect",
  "raise",
  "remove",
  "deliver",
  "ski",
  "blink",
  "clear",
  "tire",
  "protect",
  "tick",
  "shop",
  "pray",
  "correct",
  "receive",
  "brake",
  "bomb",
  "describe",
  "mend",
  "squeeze",
  "open",
  "welcome",
  "whisper",
  "fear",
  "pack",
  "settle",
  "scatter",
  "rob",
  "hand",
  "exist",
  "zip",
  "wish",
  "separate",
  "fix",
  "pedal",
  "tempt",
  "kneel",
  "sail",
  "observe",
  "replace",
  "answer",
  "laugh",
  "play",
  "multiply",
  "repeat",
  "trust",
  "inject",
  "wrestle",
  "argue",
  "guess",
  "decide",
  "deceive",
  "dare",
  "book",
  "wait",
  "grease",
  "glow",
  "step",
  "allow",
  "steer",
  "gaze",
  "rain",
  "cause",
  "clap",
  "peep",
  "wriggle",
  "explode",
  "rush",
  "bolt",
  "announce",
  "match",
  "concern",
  "colour",
  "type",
  "spill",
  "exercise",
  "pick",
  "delay",
  "nail",
  "screw",
  "slow",
  "ask",
  "shiver",
  "add",
  "dream",
  "warn",
  "kick",
  "plan",
  "memorise",
  "bump",
  "arrive",
  "intend",
  "polish",
  "trip",
  "subtract",
  "include",
  "trace",
  "handle",
  "strip",
  "spot",
  "fry",
  "flash",
  "man",
  "visit",
  "squeak",
  "copy",
  "reflect",
  "comb",
  "reign",
  "scream",
  "chew",
  "fetch",
  "rule",
  "label",
  "invite",
  "transport",
  "present",
  "sneeze",
  "provide",
  "look",
  "haunt",
  "fail",
  "push",
  "point",
  "stain",
  "interfere",
  "cheer",
  "afford",
  "blind",
  "flow",
  "shelter",
  "glue",
  "amuse",
  "accept",
  "bruise",
  "post",
  "live",
  "fasten",
  "mark",
  "escape",
  "hum",
  "curve",
  "follow",
  "land",
  "dust",
  "stay",
  "occur",
  "boil",
  "scratch",
  "dislike",
  "pat",
  "dress",
  "turn",
  "promise",
  "offer",
  "challenge",
  "drown",
  "slip",
  "trap",
  "pop",
  "mess up",
  "force",
  "stuff",
  "roll",
  "care",
  "consist",
  "jump",
  "level",
  "hop",
  "bathe",
  "wobble",
  "consider",
  "murder",
  "excuse",
  "avoid",
  "disagree",
  "back",
  "frame",
  "shave",
  "charge",
  "pump",
  "instruct",
  "tickle",
  "travel",
  "moan",
  "peck",
  "nest",
  "drag",
  "fire",
  "explain",
  "scold",
  "attach",
  "carry",
  "suit",
  "paint",
  "applaud",
  "breathe",
  "saw",
  "report",
  "attack",
  "marry",
  "complain",
  "communicate",
  "own",
  "hunt",
  "retire",
  "spray",
  "complete",
  "admit",
  "store",
  "water",
  "request",
  "extend",
  "grate",
  "drum",
  "call",
  "fill",
  "bore",
  "punish",
  "moor",
  "punch",
  "save",
  "fool",
  "dam",
  "bow",
  "love",
  "increase",
  "start",
  "listen",
  "hammer",
  "mourn",
  "want",
  "appear",
  "smile",
  "suppose",
  "march",
  "continue",
  "buzz",
  "wink",
  "apologise",
  "face",
  "clip",
  "close",
  "flap",
  "owe",
  "rely",
  "supply",
  "dance",
  "attempt",
  "blush",
  "wipe",
  "share",
  "burn",
  "bubble",
  "empty",
  "disarm",
  "cross",
  "search",
  "soak",
  "agree",
  "launch",
  "surround",
  "plant",
  "pour",
  "lighten",
  "destroy",
  "detect",
];
const genericNoun = [
  "adjustment",
  "straw",
  "person",
  "pipe",
  "coach",
  "distribution",
  "bite",
  "can",
  "snail",
  "plane",
  "flock",
  "dog",
  "grandfather",
  "achiever",
  "act",
  "beds",
  "trains",
  "wall",
  "school",
  "minute",
  "clam",
  "rat",
  "notebook",
  "sister",
  "power",
  "government",
  "tomatoes",
  "week",
  "river",
  "quiet",
  "move",
  "rose",
  "crate",
  "bubble",
  "loss",
  "laugh",
  "camera",
  "mouth",
  "development",
  "flag",
  "appliance",
  "slave",
  "match",
  "trucks",
  "bead",
  "screw",
  "hot",
  "sidewalk",
  "minister",
  "bell",
  "lamp",
  "coat",
  "queen",
  "cherries",
  "temper",
  "lip",
  "suit",
  "partner",
  "voice",
  "cave",
  "plough",
  "wind",
  "harbor",
  "tub",
  "dock",
  "hands",
  "van",
  "calculator",
  "cause",
  "history",
  "effect",
  "hour",
  "experience",
  "back",
  "bucket",
  "chickens",
  "aunt",
  "death",
  "friction",
  "amount",
  "fuel",
  "stem",
  "sisters",
  "yam",
  "event",
  "tooth",
  "discussion",
  "maid",
  "guitar",
  "bit",
  "chance",
  "time",
  "fairies",
  "key",
  "trail",
  "spot",
  "tank",
  "oranges",
  "attraction",
  "range",
  "ants",
  "boot",
  "education",
  "letters",
  "iron",
  "kitty",
  "belief",
  "noise",
  "acoustics",
  "cheese",
  "fire",
  "foot",
  "chicken",
  "rest",
  "bikes",
  "hope",
  "stop",
  "shade",
  "quiver",
  "sand",
  "farm",
  "zoo",
  "cabbage",
  "oil",
  "deer",
  "throne",
  "look",
  "rainstorm",
  "talk",
  "linen",
  "pigs",
  "wound",
  "liquid",
  "badge",
  "vase",
  "button",
  "order",
  "bath",
  "airport",
  "business",
  "cow",
  "visitor",
  "committee",
  "base",
  "book",
  "cemetery",
  "ice",
  "zinc",
  "afterthought",
  "zipper",
  "size",
  "frogs",
  "wren",
  "chess",
  "sneeze",
  "robin",
  "lake",
  "bomb",
  "memory",
  "snakes",
  "decision",
  "head",
  "receipt",
  "toothbrush",
  "humor",
  "war",
  "cats",
  "pull",
  "industry",
  "offer",
  "insurance",
  "test",
  "spy",
  "hat",
  "north",
  "behavior",
  "battle",
  "parcel",
  "grade",
  "dinosaurs",
  "substance",
  "window",
  "gate",
  "side",
  "houses",
  "view",
  "cap",
  "agreement",
  "honey",
  "plate",
  "pest",
  "fish",
  "cannon",
  "yarn",
  "bed",
  "mom",
  "writer",
  "interest",
  "record",
  "scissors",
  "rod",
  "guide",
  "furniture",
  "credit",
  "gold",
  "spoon",
  "activity",
  "club",
  "theory",
  "coal",
  "sponge",
  "knot",
  "mass",
  "house",
  "bone",
  "mailbox",
  "drink",
  "burst",
  "water",
  "cherry",
  "flavor",
  "sky",
  "coast",
  "island",
  "son",
  "smoke",
  "birthday",
  "babies",
  "self",
  "planes",
  "impulse",
  "metal",
  "thought",
  "salt",
  "pollution",
  "table",
  "surprise",
  "scene",
  "health",
  "rifle",
  "shock",
  "picture",
  "plastic",
  "arithmetic",
  "border",
  "army",
  "place",
  "oven",
  "marble",
  "skate",
  "voyage",
  "question",
  "cobweb",
  "mark",
  "shoe",
  "beginner",
  "star",
  "porter",
  "magic",
  "collar",
  "team",
  "sofa",
  "moon",
  "chalk",
  "sink",
  "lettuce",
  "turkey",
  "doll",
  "land",
  "brake",
  "existence",
  "smile",
  "thing",
  "car",
  "loaf",
  "jellyfish",
  "play",
  "swing",
  "sign",
  "cook",
  "horses",
  "laborer",
  "company",
  "office",
  "snake",
  "volleyball",
  "fly",
  "thumb",
  "top",
  "division",
  "celery",
  "pie",
  "measure",
  "oatmeal",
  "name",
  "toes",
  "limit",
  "veil",
  "hill",
  "driving",
  "jam",
  "quilt",
  "horse",
  "secretary",
  "pleasure",
  "squirrel",
  "wire",
  "finger",
  "peace",
  "roof",
  "structure",
  "curve",
  "zebra",
  "rings",
  "alarm",
  "cushion",
  "monkey",
  "grip",
  "treatment",
  "balance",
  "flower",
  "mitten",
  "crow",
  "twig",
  "glove",
  "advice",
  "song",
  "weather",
  "bird",
  "governor",
  "science",
  "seashore",
  "pot",
  "sort",
  "berry",
  "zephyr",
  "basket",
  "value",
  "expansion",
  "arm",
  "cub",
  "pump",
  "things",
  "competition",
  "wheel",
  "toy",
  "kick",
  "cloth",
  "step",
  "respect",
  "church",
  "writing",
  "push",
  "milk",
  "jar",
  "low",
  "geese",
  "meat",
  "tree",
  "songs",
  "dress",
  "party",
  "heat",
  "selection",
  "wash",
  "nerve",
  "rain",
  "amusement",
  "mice",
  "error",
  "rock",
  "caption",
  "desire",
  "edge",
  "start",
  "children",
  "wealth",
  "neck",
  "nose",
  "playground",
  "nation",
  "coil",
  "pan",
  "donkey",
  "field",
  "approval",
  "canvas",
  "position",
  "note",
  "ray",
  "cat",
  "library",
];
const genericAdj = [
  "tedious",
  "therapeutic",
  "acid",
  "crooked",
  "available",
  "tasty",
  "real",
  "kind",
  "fancy",
  "solid",
  "brash",
  "unaccountable",
  "late",
  "necessary",
  "electric",
  "one",
  "zealous",
  "versed",
  "exciting",
  "keen",
  "tasteful",
  "ugliest",
  "adjoining",
  "questionable",
  "tasteless",
  "productive",
  "worthless",
  "evasive",
  "square",
  "separate",
  "upset",
  "typical",
  "mighty",
  "plant",
  "hushed",
  "helpless",
  "smooth",
  "colossal",
  "infamous",
  "scandalous",
  "pointless",
  "lethal",
  "melodic",
  "terrific",
  "inexpensive",
  "salty",
  "puzzling",
  "seemly",
  "learned",
  "venomous",
  "vagabond",
  "puny",
  "mere",
  "unkempt",
  "private",
  "breezy",
  "tremendous",
  "exultant",
  "ugly",
  "chunky",
  "teeny-tiny",
  "snobbish",
  "entertaining",
  "friendly",
  "opposite",
  "pumped",
  "fascinated",
  "picayune",
  "wanting",
  "subsequent",
  "abrasive",
  "uttermost",
  "abiding",
  "narrow",
  "holistic",
  "slow",
  "murky",
  "probable",
  "mountainous",
  "bouncy",
  "upbeat",
  "foamy",
  "sophisticated",
  "furry",
  "nonchalant",
  "warlike",
  "rightful",
  "extra-large",
  "calculating",
  "aspiring",
  "volatile",
  "vague",
  "icy",
  "frightening",
  "historical",
  "complete",
  "enormous",
  "swift",
  "grumpy",
  "empty",
  "knowing",
  "adventurous",
  "ruthless",
  "hypnotic",
  "remarkable",
  "understood",
  "feeble",
  "pushy",
  "mature",
  "paltry",
  "next",
  "shivering",
  "flagrant",
  "worried",
  "dead",
  "needless",
  "natural",
  "curly",
  "coherent",
  "abusive",
  "tart",
  "tough",
  "chief",
  "concerned",
  "physical",
  "whimsical",
  "useful",
  "exotic",
  "nimble",
  "hallowed",
  "smelly",
  "internal",
  "meek",
  "tested",
  "hurried",
  "marked",
  "jittery",
  "crowded",
  "gaudy",
  "proud",
  "used",
  "last",
  "incompetent",
  "fumbling",
  "nosy",
  "possessive",
  "puffy",
  "workable",
  "fretful",
  "sweet",
  "disastrous",
  "dramatic",
  "capable",
  "courageous",
  "heady",
  "shrill",
  "vulgar",
  "kindhearted",
  "cagey",
  "parsimonious",
  "abaft",
  "ethereal",
  "talented",
  "noxious",
  "raspy",
  "frantic",
  "doubtful",
  "fantastic",
  "tan",
  "screeching",
  "abnormal",
  "rustic",
  "recondite",
  "cut",
  "annoyed",
  "chemical",
  "ossified",
  "cluttered",
  "stupid",
  "clumsy",
  "clean",
  "exuberant",
  "cumbersome",
  "enchanted",
  "plain",
  "malicious",
  "romantic",
  "itchy",
  "light",
  "scintillating",
  "grouchy",
  "gainful",
  "four",
  "pricey",
  "three",
  "ambitious",
  "brown",
  "healthy",
  "willing",
  "new",
  "jazzy",
  "soggy",
  "oval",
  "madly",
  "nutritious",
  "unused",
  "kaput",
  "straight",
  "defective",
  "ripe",
  "wide",
  "whole",
  "insidious",
  "foregoing",
  "poised",
  "unusual",
  "smoggy",
  "optimal",
  "voiceless",
  "curved",
  "statuesque",
  "slim",
  "hard",
  "numberless",
  "successful",
  "brawny",
  "ratty",
  "ultra",
  "accurate",
  "rigid",
  "impossible",
  "ajar",
  "elegant",
  "heavy",
  "ablaze",
  "lackadaisical",
  "moldy",
  "lopsided",
  "black",
  "reflective",
  "mushy",
  "wholesale",
  "quarrelsome",
  "closed",
  "equal",
  "awake",
  "wide-eyed",
  "unwieldy",
  "mellow",
  "squealing",
  "shallow",
  "foolish",
  "measly",
  "poor",
  "cloistered",
  "calm",
  "shy",
  "clammy",
  "damaged",
  "jaded",
  "unable",
  "painstaking",
  "overjoyed",
  "faint",
  "steadfast",
  "brave",
  "receptive",
  "filthy",
  "amuck",
  "tacit",
  "living",
  "normal",
  "useless",
  "beneficial",
  "protective",
  "adaptable",
  "diligent",
  "uncovered",
  "meaty",
  "political",
  "rude",
  "stale",
  "nasty",
  "demonic",
  "perpetual",
  "fragile",
  "right",
  "striped",
  "rare",
  "guarded",
  "decorous",
  "wrathful",
  "strange",
  "daffy",
  "wealthy",
  "humdrum",
  "familiar",
  "animated",
  "aware",
  "silly",
  "oafish",
  "selfish",
  "royal",
  "skillful",
  "excellent",
  "wry",
  "flippant",
  "obeisant",
  "mammoth",
  "milky",
  "substantial",
  "modern",
  "huge",
  "bustling",
  "unequaled",
  "adamant",
  "green",
  "confused",
  "hapless",
  "ceaseless",
  "spotty",
  "dangerous",
  "defeated",
  "dull",
  "silky",
  "wet",
  "hurt",
  "efficacious",
  "best",
  "humorous",
  "magnificent",
  "faulty",
  "ruddy",
  "accessible",
  "second",
  "languid",
  "uptight",
  "small",
  "odd",
  "detailed",
  "didactic",
  "cute",
  "steep",
  "mixed",
  "squeamish",
  "truculent",
  "aboard",
  "deadpan",
  "sweltering",
  "stupendous",
  "overwrought",
  "precious",
  "pink",
  "afraid",
  "bitter",
  "husky",
  "wacky",
  "gruesome",
  "plucky",
  "scrawny",
  "famous",
  "old-fashioned",
  "fabulous",
  "obscene",
  "repulsive",
  "boundless",
  "handy",
  "greasy",
  "irritating",
  "roomy",
  "somber",
  "stormy",
  "two",
  "nippy",
  "half",
  "imperfect",
  "shaky",
  "nifty",
  "succinct",
  "hollow",
  "lonely",
  "sturdy",
  "giant",
  "fierce",
  "lazy",
  "bright",
  "maddening",
  "lamentable",
  "subdued",
  "tawdry",
  "crabby",
];

export const planetNameWords = [
  "abandon",
  "abate",
  "aberrant",
  "blaze",
  "abounding",
  "absorb",
  "acoustic",
  "harmony",
  "act",
  "action",
  "actor",
  "add",
  "addition",
  "adjustment",
  "admit",
  "advice",
  "advise",
  "afraid",
  "aggressive",
  "agonizing",
  "ahead",
  "airplane",
  "airport",
  "alight",
  "alike",
  "aloof",
  "ambitious",
  "amuck",
  "amusement",
  "anger",
  "animated",
  "annoyed",
  "anxious",
  "apparel",
  "applaud",
  "apple",
  "apples",
  "apply",
  "aquatic",
  "argument",
  "aromatic",
  "arrest",
  "assert",
  "assert",
  "attempt",
  "audacious",
  "auspicious",
  "authority",
  "avoid",
  "awake",
  "badge",
  "bait",
  "ball",
  "band",
  "band",
  "banish",
  "barbarous",
  "base",
  "base",
  "baseball",
  "basket",
  "bat",
  "bawdy",
  "bead",
  "bear",
  "beast",
  "beast",
  "beautify",
  "beds",
  "befall",
  "befitting",
  "behold",
  "believe",
  "bell",
  "bells",
  "bend",
  "berserk",
  "beseech",
  "big",
  "birthday",
  "bite",
  "bite",
  "blade",
  "blow",
  "blush",
  "bone",
  "books",
  "books",
  "boot",
  "bottle",
  "bouncy",
  "boundary",
  "bow",
  "brainy",
  "brass",
  "breath",
  "breathe",
  "bridge",
  "bright",
  "bring",
  "broadcast",
  "brother",
  "brothers",
  "bulb",
  "burly",
  "burst",
  "bury",
  "bushes",
  "busy",
  "button",
  "cabbage",
  "cagey",
  "calculating",
  "callous",
  "calm",
  "can",
  "canvas",
  "capable",
  "careless",
  "carriage",
  "cars",
  "cast",
  "catch",
  "cease",
  "celebrate",
  "cent",
  "chain",
  "chairs",
  "change",
  "changeable",
  "channel",
  "charge",
  "charming",
  "chat",
  "cheese",
  "cherries",
  "chide",
  "chief",
  "children",
  "chip",
  "choke",
  "clean",
  "cleave",
  "clocks",
  "closed",
  "clutch",
  "cluttered",
  "coast",
  "collapse",
  "collapse",
  "collar",
  "colour",
  "combative",
  "comfortable",
  "competition",
  "complain",
  "complete",
  "complex",
  "condition",
  "confuse",
  "connection",
  "connote",
  "conserve",
  "conspire",
  "constitute",
  "constrain",
  "contribute",
  "convene",
  "converge",
  "convict",
  "convince",
  "coo",
  "cooing",
  "cool",
  "cooperative",
  "copper",
  "cork",
  "corrod",
  "cough",
  "country",
  "cow",
  "cowardly",
  "crabby",
  "cracker",
  "crashing",
  "creep",
  "crib",
  "cry",
  "cub",
  "cumbersome",
  "cup",
  "cure",
  "curly",
  "curve",
  "cut",
  "cute",
  "cute",
  "daffy",
  "daffy",
  "daily",
  "dance",
  "dark",
  "daughter",
  "dazzling",
  "deafening",
  "debonair",
  "decision",
  "declare",
  "decorous",
  "defiant",
  "degree",
  "delay",
  "delicate",
  "delirious",
  "depend",
  "desire",
  "destruction",
  "develop",
  "die",
  "different",
  "digestion",
  "diligent",
  "dim",
  "diminish",
  "dinner",
  "direct",
  "dirty",
  "disgusted",
  "dispensable",
  "display",
  "display",
  "distinct",
  "dive",
  "dive",
  "divide",
  "dock",
  "doctor",
  "dog",
  "doll",
  "drain",
  "draw",
  "dream",
  "dreary",
  "drink",
  "drive",
  "drop",
  "dry",
  "dull",
  "dust",
  "dynamic",
  "dysfunctional",
  "earn",
  "earthy",
  "eatable",
  "edge",
  "education",
  "efficacious",
  "egg",
  "eggnog",
  "elephant",
  "elite",
  "elite",
  "eminent",
  "empower",
  "empty",
  "enchanting",
  "encourage",
  "end",
  "end",
  "endorse",
  "enjoy",
  "enlighten",
  "enormous",
  "enthusiastic",
  "envious",
  "erratic",
  "escape",
  "evasive",
  "event",
  "excited",
  "exclusive",
  "expensive",
  "expert",
  "extend",
  "extra-large",
  "extra-small",
  "eyes",
  "fabulous",
  "fair",
  "fall",
  "fan",
  "far",
  "fascinated",
  "fast",
  "fear",
  "feather",
  "feed",
  "feet",
  "field",
  "fight",
  "finger",
  "flaky",
  "flame",
  "flap",
  "flee",
  "flesh",
  "flock",
  "floor",
  "flop",
  "flower",
  "fly",
  "fly",
  "fog",
  "fold",
  "follow",
  "foregoing",
  "four",
  "freeze",
  "friend",
  "frighten",
  "frightened",
  "fulfil",
  "funny",
  "garrulous",
  "gash",
  "gentle",
  "gentle",
  "giant",
  "giant",
  "gigantic",
  "giraffe",
  "glamorous",
  "glass",
  "glass",
  "glib",
  "glorious",
  "glove",
  "glow",
  "godly",
  "golden",
  "govern",
  "grade",
  "grape",
  "grass",
  "green",
  "green",
  "grind",
  "ground",
  "group",
  "grow",
  "growth",
  "gruesome",
  "grumpy",
  "guarded",
  "guess",
  "guide",
  "gusty",
  "haircut",
  "hallowed",
  "halting",
  "handsome",
  "hang",
  "happy",
  "harbor",
  "harm",
  "harmonious",
  "hat",
  "heal",
  "hearing",
  "heart",
  "heat",
  "hiss",
  "honey",
  "hope",
  "hose",
  "hospitable",
  "humorous",
  "hurt",
  "hurt",
  "hush",
  "hustle",
  "hydrant",
  "hypnotic",
  "hypnotize",
  "ice",
  "idealize",
  "ill-fated",
  "illustrate",
  "imaginary",
  "imagine",
  "imminent",
  "immolate",
  "impart",
  "impartial",
  "impend",
  "impend",
  "imperfect",
  "imperil",
  "imperil",
  "implant",
  "implicate",
  "impolite",
  "imported",
  "income",
  "incompetent",
  "increase",
  "incredible",
  "indicate",
  "induce",
  "indulge",
  "industry",
  "infect",
  "inherit",
  "initiate",
  "innocent",
  "inspire",
  "insult",
  "insurance",
  "invent",
  "invention",
  "iron",
  "jaded",
  "jail",
  "jam",
  "jealous",
  "jeans",
  "jellyfish",
  "jobless",
  "jumbled",
  "kill",
  "kiss",
  "knot",
  "knowing",
  "lade",
  "ladybug",
  "lamentable",
  "lamp",
  "languid",
  "latch",
  "laugh",
  "lavish",
  "lead",
  "leak",
  "lean",
  "leather",
  "lend",
  "let",
  "letters",
  "lick",
  "lift",
  "lip",
  "list",
  "listen",
  "listen",
  "lively",
  "lock",
  "lock",
  "locket",
  "lonely",
  "longing",
  "look",
  "loose",
  "lose",
  "love",
  "low",
  "lowly",
  "ludicrous",
  "lumpy",
  "lunch",
  "lyrical",
  "maddening",
  "magnificent",
  "mailbox",
  "make",
  "manage",
  "manager",
  "marble",
  "mass",
  "materialistic",
  "meal",
  "measly",
  "measure",
  "meat",
  "meek",
  "meet",
  "mellow",
  "mere",
  "mind",
  "mine",
  "miniature",
  "minister",
  "mint",
  "miscreant",
  "motionless",
  "motivate",
  "mountain",
  "multiply",
  "mundane",
  "mushy",
  "music",
  "nappy",
  "need",
  "nerve",
  "nest",
  "news",
  "next",
  "nifty",
  "nimble",
  "noise",
  "noiseless",
  "noisy",
  "nostalgic",
  "notify",
  "notify",
  "null",
  "nut",
  "oatmeal",
  "oatmeal",
  "observation",
  "oceanic",
  "odd",
  "old-fashioned",
  "onerous",
  "open",
  "operation",
  "opinion",
  "oranges",
  "order",
  "ordinary",
  "organization",
  "originate",
  "outgoing",
  "output",
  "outrageous",
  "outstanding",
  "oven",
  "overflow",
  "overrated",
  "owe",
  "own",
  "pacify",
  "pain",
  "pale",
  "pale",
  "paltry",
  "pan",
  "paper",
  "partake",
  "participate",
  "party",
  "passenger",
  "past",
  "patch",
  "pay",
  "peace",
  "peaceful",
  "penitent",
  "permissible",
  "pet",
  "phone",
  "physical",
  "pink",
  "piquant",
  "place",
  "plain",
  "plan",
  "please",
  "pointless",
  "poison",
  "police",
  "ponder",
  "poor",
  "possessive",
  "praise",
  "precious",
  "premium",
  "prescribe",
  "preserve",
  "preset",
  "prickly",
  "probable",
  "proceed",
  "protect",
  "public",
  "pull",
  "pull",
  "punish",
  "puny",
  "purpose",
  "push",
  "puzzling",
  "quack",
  "qualify",
  "quarrel",
  "quilt",
  "quince",
  "quirky",
  "quit",
  "quiver",
  "quixotic",
  "ragged",
  "rain",
  "rapid",
  "rate",
  "ratty",
  "reach",
  "reaction",
  "read",
  "rebel",
  "recall",
  "receipt",
  "receptive",
  "recondite",
  "redo",
  "refer",
  "reflective",
  "refuse",
  "regret",
  "regular",
  "relate",
  "relation",
  "rend",
  "renew",
  "repair",
  "repeat",
  "reply",
  "resell",
  "resolve",
  "resolve",
  "respect",
  "respect",
  "review",
  "rhythm",
  "rifle",
  "ring",
  "ring",
  "river",
  "roar",
  "roasted",
  "rob",
  "rob",
  "rock",
  "rock",
  "roomy",
  "rose",
  "rotten",
  "route",
  "rush",
  "sable",
  "sag",
  "salt",
  "salve",
  "same",
  "sample",
  "sanctify",
  "sash",
  "sassy",
  "satirise",
  "savor",
  "saw",
  "say",
  "scar",
  "scarf",
  "scarify",
  "scary",
  "school",
  "scientific",
  "scold",
  "scorch",
  "scrawl",
  "scrawny",
  "sea",
  "search",
  "seashore",
  "seat",
  "secretive",
  "sedate",
  "seemly",
  "sense",
  "sentence",
  "sever",
  "sew",
  "shake",
  "sharp",
  "shear",
  "shed",
  "sheet",
  "ship",
  "shivering",
  "shock",
  "shun",
  "shy",
  "side",
  "sight",
  "signify",
  "silky",
  "silly",
  "silver",
  "simple",
  "sing",
  "sink",
  "sit",
  "six",
  "skid",
  "skillful",
  "sleep",
  "sleepy",
  "slide",
  "slim",
  "slink",
  "slip",
  "slippery",
  "slow",
  "smell",
  "smooth",
  "snail",
  "snake",
  "snap",
  "sneeze",
  "soap",
  "sob",
  "society",
  "soda",
  "soggy",
  "solicit",
  "sore",
  "sorrow",
  "sound",
  "soup",
  "sour",
  "spade",
  "spark",
  "spectacular",
  "spill",
  "spiteful",
  "splendid",
  "spotted",
  "spotty",
  "spray",
  "spring",
  "square",
  "squeamish",
  "standing",
  "stare",
  "statuesque",
  "steady",
  "step",
  "sticky",
  "stir",
  "stitch",
  "stomach",
  "store",
  "story",
  "straight",
  "strain",
  "straw",
  "stream",
  "street",
  "strew",
  "strive",
  "strong",
  "stupendous",
  "submit",
  "succeed",
  "suck",
  "suggest",
  "summon",
  "sun",
  "superb",
  "superficial",
  "suppose",
  "surround",
  "survive",
  "swallow",
  "sway",
  "sweet",
  "swim",
  "swing",
  "tail",
  "talented",
  "talk",
  "tall",
  "tan",
  "tart",
  "taste",
  "tasteless",
  "tax",
  "teach",
  "tearful",
  "teeth",
  "tense",
  "terminate",
  "terrible",
  "terrific",
  "terrify",
  "testy",
  "thankful",
  "theory",
  "therapeutic",
  "thing",
  "think",
  "thinkable",
  "third",
  "thought",
  "throne",
  "thumb",
  "ticket",
  "tie",
  "tiger",
  "tiger",
  "tightfisted",
  "time",
  "time",
  "time",
  "tired",
  "tongue",
  "toothbrush",
  "toothsome",
  "toss",
  "town",
  "toys",
  "trample",
  "transfer",
  "tray",
  "tree",
  "tremble",
  "tremendous",
  "tricky",
  "triumph",
  "trouble",
  "truculent",
  "trust",
  "try",
  "tub",
  "turn",
  "twig",
  "twist",
  "two",
  "type",
  "ugliest",
  "ugly",
  "ultra",
  "umbrella",
  "undesirable",
  "undo",
  "unequaled",
  "unkempt",
  "unsightly",
  "unsuitable",
  "upset",
  "used",
  "utopian",
  "utter",
  "vacation",
  "vacation",
  "vanish",
  "vengeful",
  "verify",
  "vessel",
  "vigorous",
  "walk",
  "wander",
  "warn",
  "wasteful",
  "watch",
  "water",
  "waylay",
  "weak",
  "weigh",
  "welcome",
  "wheel",
  "whistle",
  "wide-eyed",
  "wing",
  "witty",
  "wool",
  "wrench",
  "write",
  "wry",
  "xenology",
  "yarn",
  "year",
  "yield",
  "yoke",
  "zephyr",
  "zipper",
  "zippy",
  "otter",
  "llama",
  "aardvark",
  "aggravated",
  "luck",
  "luxury",
  "satisfaction",
  "trust",
  "wisdom",
  "worry",
  "pride",
  "sin",
  "sloth",
  "greed",
  "opinion",
  "movement",
  "infancy",
  "envy",
  "evil",
  "failure",
  "success",
  "birth",
  "calm",
  "shallow",
  "insect",
  "fox",
  "panda",
  "penguin",
  "lodge",
  "town",
  "awareness",
  "childhood",
  "chaos",
];

export const planetTagAdj = [
  "vast",
  "frigid",
  "boiling",
  "cold",
  "immense",
  "gaseous",
  "alluring",
  "groovy",
  "scattered",
  "frightening",
  "wacky",
  "lush",
  "green",
  "empty",
  "elastic",
  "calm",
  "fragile",
  "guarded",
  "various",
  "nebulous",
  "electronic",
  "heavenly",
  "cheerful",
  "delicious",
  "silky",
  "ritzy",
  "perfect",
  "pristine",
  "damaged",
  "unruly",
  "unsightly",
  "mysterious",
  "shallow",
  "deep",
  "trite",
  "noxious",
  "spectacular",
  "furtive",
  "jittery",
  "pleasant",
  "craggy",
  "overgrown",
  "wonderful",
  "dynamic",
  "harmonious",
  "deafening",
];

export const planetTagNoun = [
  "tundra",
  "desert",
  "oasis",
  "wasteland",
  "garden",
  "ocean",
  "savannah",
  "tropic",
  "shrubland",
  "forest",
  "jungle",
  "biosphere",
  "ecoregion",
  "delta",
  "ruin",
  "abyss",
  "hydrotherm",
  "marsh",
  "benthic",
  "ptuitary",
  "panda",
  "bear",
  "Vitalik",
  "cyrptokitty",
  "bitcoin",
];

export const blurbGrammar = {
  geography: [
    "mountains",
    "hills",
    "rivers",
    "oceans",
    "forests",
    "grasslands",
    "plains",
    "alienscapes",
    "tetraspheres",
    "ruins",
    "tropics",
    "crags",
    "cliffs",
    "icebergs",
    "volcanoes",
    ...Array(7).fill("#geography# and #geography#"),
  ],
  populates: ["#stretch# across the #landscape# as far as #eyecansee#"],
  landscape: [
    "landscape",
    "horizon",
    "view",
    "surface",
    "skyline",
    "sky",
    "sea",
    "scenery",
    "topography",
    "topology",
  ],
  stretch: ["reach", "stretch", "extend", "spread"],
  noun: genericNoun,
  verb: genericVerb,
  adj: genericAdj,
  eyecansee: [
    "the eye can see",
    "a cow can run",
    "a bear can walk",
    "a network request can reach",
    ...Array(2).fill("#adj.a# #noun# can #verb#"),
  ],
  air: ["atmosphere", "air", "weather", "gaseous composition"],
  fruit: [
    "magno",
    "blueberry",
    "raspberry",
    "pomelo",
    "pineapple",
    "apple",
    "orange",
    "watermelon",
  ],
  colors: [
    "in many colors",
    "pervasively",
    "sparingly",
    "in full abundance",
    "in small amounts",
    "like #noun.a# might #verb# #noun.a#",
    "across the horizon",
    "over the land",
  ],
  habitat: [
    "rainforest",
    "jungles",
    "ocean",
    "icy landscape",
    "deserts",
    "sky",
    "magma pools",
    "acid puddles",
  ],
  populate: [
    "populate",
    "pervade",
    "fill",
    "inhabit",
    "reside in",
    "occupy",
    "hunt in",
    "hunt #species# in",
    "gather #flora# in",
  ],
  species: ["fish", "mammal", "bird", "amphibian", "quadruped", "biped"],
  many: [
    "many",
    "a few",
    "a single",
    "two common",
    "an uncannily human-like",
    "a seemingly robotic",
    "a silicon-based",
  ],
  bloom: [
    "pervade",
    "bloom",
    "grow",
    "spread",
    "peek out",
    "pepper the landscape",
    "#stretch# out",
    "flourish",
    "meekly survive",
    "dot the barren land",
  ],
  flora: [
    "flowers",
    "trees",
    "tall grasses",
    "#fruit#-like fruit",
    "marsh mallows",
    "deciduous forestry",
    "massive fungi",
    "fields of mold",
    "wheat-like crops",
    "mysterious fruits",
  ],
  descair: [
    "hot",
    "cold",
    "heavy",
    "warm",
    "dense",
    "viscous",
    "nitrogen-dense",
    "toxic",
    "nauseating",
    "frigid",
    "icy",
    "humid",
    "saturated",
    "dry",
    ...Array(10).fill("#descair# and #descair#"),
  ],

  gravity: ["heavy", "light", "strong", "unusual, somehow"],
  funfact: [
    "the gravity seems #gravity#",
    "it smells like #noun.s#",
    "there are fruits on the trees. They look like #noun.s#",
    "the soil is #adj#. Perhaps that's why the plants look like #noun.s#",
  ],
};

const dflaunch = new Date("August 8, 2020 00:00:00");
export const blurb2grammar = {
  learned: [
    "discovered ways",
    "researched methods",
    "trained in the mountains in order",
    "found ancient texts describing how",
  ],
  live: [
    "live",
    "farm",
    "grow",
    "thrive",
    "develop economies",
    "raise bears",
    "trade ETH",
    "buy cryptokitties",
  ],
  sun: [
    "sun",
    "mothers",
    "bears",
    "core",
    "oceans",
    "fauna",
    "tectonic plates",
    "volcanos",
    "toxic gases",
  ],
  flock: [
    "flock",
    "army",
    "fleet",
    "ensemble",
    "barrage",
    "array",
    "aggregation",
    "mixture",
  ],
  bads: [
    "solar flares",
    "polar bears",
    "foxes",
    "in-browser miners",
    "toxic gas",
    "evil ducks",
    "man-eating plants",
    "radioactive minerals",
    "neutrino rays",
    "eigenstates",
    "sidechains",
    "51% attacks",
  ],
  sends: [
    "sends",
    "generates",
    "publicises",
    "causes",
    "births",
    "spawns",
    "produces",
    "provokes",
    "kindles",
    "fosters",
    "sparks",
    "throws",
    "delivers",
  ],
  sometimes: [
    "every day",
    "every minute",
    "periodically",
    "whenever @VitalikButerin tweets",
    "when Jim feels like it",
    "every second",
    "every four seconds",
    "every Planck length",
    "every quantum nanostate",
    "every decade",
    `every ${Math.floor(50 + Math.random() * 100)} galactic time-units`,
  ],
  years: [
    "years",
    "seconds",
    "days",
    `${Date.now() - (dflaunch as unknown as number)}ms since the Dark Forest universe began`,
  ],
  removed: [
    "defended against",
    "removed",
    "bribed",
    "nullified",
    "eliminated",
    "exiled",
    "dark realm-ed",
    "destroyed",
  ],
  throwing: ["throwing", "tossing", "firing", "launching"],
  warbears: [
    "warbears",
    "drones",
    "guided missiles",
    "ICBMs",
    "spiral energy",
    "smaller galaxies",
    "storybooks",
    "laser beams",
  ],
  lesson: [
    "#friends#", // the real treasure was the friends we made along the way
    "#chocolates#", // life is like a box of chocolates, since you never know what you're gonna get
    "#willway#", // when there's a will, there's a way
  ],
  friends: ["the real #treasure# was the #friend.s# we #made# #alongtheway#"],
  alongtheway: ["along the way", "on the way to school", "in our childhood"],
  treasure: [
    "treasure",
    "gift",
    "joy",
    "lesson",
    "battle",
    "money",
    "decentralized platform",
    "bear",
    "planet",
    "space war",
    "flux capacitor",
    "layer 2 scaling solution",
    "metaverse",
  ],
  friend: [
    "friend",
    "bear",
    "books",
    "bird",
    "toy",
    "restaurant",
    "computer",
    "spaceship",
    "asteroid",
    "metaverse",
  ],
  made: [
    "made",
    "ate",
    "gained",
    "lost",
    "identified",
    "organized",
    "found",
    "destroyed",
    "educated",
    "confirmed",
  ],
  chocolates: [
    "#life# is like a #box# of #chocolate.s# - you never #know# what you're gonna #get#",
  ],
  life: [
    "ethereum",
    "blockchain",
    "life",
    "dating",
    "college",
    "space warfare",
    "colonizing mars",
    "loving bears",
    "liking yourself",
  ],
  box: [
    "box",
    "container",
    "flock",
    "group",
    "squabble",
    "flamboyance",
    "classroom",
  ],
  chocolate: ["teacher", "chocolate", "candy", "meat log"],
  know: ["know", "realize", "anticipate", "enjoy", "find"],
  get: ["find", "eat", "enjoy", "delete from your browser history"],
  willway: [`#when# #theres# #will.a#, #theres# #way.a#`],
  when: [
    "when",
    "if",
    "sometimes when",
    "if Jim says",
    "when",
    "if",
    "when",
    "when",
    "when",
    "if",
    "if",
    "if",
    "where",
  ],
  theres: [`there's`, "there is never", `there isn't`],
  will: [
    "will",
    "way",
    "$50 ETH gas price",
    "decentralized hair salon",
    "strong desire to minimize self-actualization",
    "bear",
    "bad chance",
    "desire",
    "yearning",
    "determination",
    "sufficiently strong love",
    "wish",
  ],
  way: [
    "way",
    "will",
    "bear",
    "good chance",
    "bad chance",
    'book that says "#willway#"',
    "way to make it come true",
    "nothing impossible",
    "someone who will take it away from you",
    "way to lose it",
    "way to gain it",
    'life lesson: "#lesson#"',
  ],
};
