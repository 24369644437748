import type { EthAddress } from "@df/types";
import bigInt from "big-integer";
import type { Hex } from "viem";

/**
 * Converts a string to an `EthAddress`: a 0x-prefixed all lowercase hex string
 * of 40 hex characters. An object of the `EthAddress` type should only ever be
 * initialized through this constructor-like method. Throws if the provided
 * string cannot be parsed as an Ethereum address.
 *
 * @param str An address-like `string`
 */
export function address(str: string): EthAddress {
  let ret = str.toLowerCase();
  if (ret.slice(0, 2) === "0x") {
    ret = ret.slice(2);
  }

  for (const c of ret) {
    if ("0123456789abcdef".indexOf(c) === -1) {
      throw new Error("not a valid address");
    }
  }
  if (ret.length !== 40) {
    throw new Error("not a valid address");
  }

  return `0x${ret}` as EthAddress;
}

export function hexToEthAddress(hex: Hex): EthAddress {
  const res = "0x" + hex.toLocaleLowerCase().slice(-40);
  return res as EthAddress;
}

export function addressToHex(addr: EthAddress): Hex {
  return addr as Hex;
}

export function hashToInt(hash: string): number {
  const seed = bigInt(hash, 16).and(0xffffffffff).toString(16);
  return parseInt("0x" + seed);
}
